import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINTS } from '@tokens/api-endpoints.token';
import { Observable } from 'rxjs';
import { ResetPasswordRequest, ResetPasswordResponse } from '@services/password/password.types';

@Injectable({
  providedIn: 'root',
})
export class PasswordService {
  protected http = inject(HttpClient);
  protected endpoints = inject(API_ENDPOINTS);

  checkResetToken(token: string): Observable<Record<string, never>> {
    return this.http.get<Record<string, never>>(this.endpoints.check_reset_password_token, {
      params: {
        reset_password_token: token,
      },
    });
  }

  reset(data: ResetPasswordRequest): Observable<ResetPasswordResponse> {
    return this.http.put<ResetPasswordResponse>(this.endpoints.reset_password, data);
  }
}
